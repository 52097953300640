<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active"><a href="#">Daftar Dinas</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <b-form-group>
                    <b-form-input id="basicInput" placeholder="Pencarian Dinas" v-model="cari" />
                </b-form-group>
            </div>


        </div>

        <div class="row">
            <div class="col-lg-4  col-md-6 col-12" v-for="rs in rs_dinas.rs" v-bind:key='rs.id_master_kode_dinas'>
                <div class="card card-apply-job   card-app-design">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-1" style="height: 80px;">
                            <div class="media">

                                <div class="media-body">
                                    <h5 class="mb-0">{{rs.nama_dinas }}</h5>
                                    <small><b>Kode Dinas {{ rs.kode_dinas }}</b></small>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-6">
                                <b-alert variant="primary" show>
                                    <div class="alert-body text-center">
                                        <span><strong>ASB FISIK</strong> <br> {{ rs.jumlag_sub_kegiatan }}
                                            <br><small><i>Sub Kegiatan</i></small>
                                        </span>
                                    </div>
                                </b-alert>
                            </div>
                            <div class="col-md-6">
                                <b-alert variant="info" show>
                                    <div class="alert-body text-center">
                                        <span><strong>ASB NON FISIK</strong> <br>{{ rs.tidak_wajar }}<br><small><i>Sub Kegiatan</i></small></span>
                                    </div>
                                </b-alert>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-12">
                                <b-alert variant="primary" show>
                                    <div class="alert-body text-center">
                                        <div> 
                                            <span>
                                                <small><i>Total Sub Kegiatan Fisik</i></small> <br>
                                                <h3 class="d-inline mr-25">
                                                    {{ formatPrice(rs.belanja_fisk.jm_kegiatan) }}</h3><br>
                                                    <small class="text-center">Total Belanja Sub Kegiatan Fisik Menurut
                                                RKA</small><br>
                                                <sup class="text-body"><small>Rp.</small></sup>
                                                <h3 class="d-inline mr-25">
                                                    {{ formatPrice(rs.belanja_fisk.total_belanja) }},-</h3><br>
                                                <!-- <small><i>Belanja  Sub kegiatan Fisik Melebihi ASB</i></small><br>
                                                <sup class="text-body"><small>Rp.</small></sup>
                                                <h4 class="d-inline mr-25">
                                                    {{ formatPrice(rs.belanja_fisk.lebih_asb) }},-</h4><br> -->

                                            </span>

                                        </div>
                                    </div>
                                </b-alert>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <b-alert variant="warning  text-center mb-1" show>
                                    <div> 
                                            <span class="mb-1">
                                                <small><i>Total Sub Kegiatan Non Fisik</i></small> <br>
                                                <h3 class="d-inline mr-25">
                                                    {{ formatPrice(rs.belanja_non_fisik.jm_kegiatan) }}</h3><br>
                                                    <small class="text-center">Total Belanja Sub Kegiatan Non Fisik Menurut
                                                RKA</small><br>
                                                <sup class="text-body"><small>Rp.</small></sup>
                                                <h3 class="d-inline mr-25">
                                                    {{ formatPrice(rs.belanja_non_fisik.total_belanja) }},-</h3><br>
                                                <!-- <small><i>Belanja  Sub kegiatan Non Fisik Melebihi ASB</i></small><br>
                                                <sup class="text-body"><small>Rp.</small></sup>
                                                <h4 class="d-inline mr-25">
                                                    {{ formatPrice(rs.belanja_non_fisik.lebih_asb) }},-</h4><br> -->

                                            </span>

                                        </div>
                                </b-alert>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <b-alert variant="warning" show>
                                    <div class="alert-body text-center">
                                        <div><span class="text-center">Total Belanja Sub kegiatan Melebihi ASB</span><br>
                                            <sup class="text-body"><small>Rp.</small></sup>
                                            <h3 class="d-inline mr-25">{{ formatPrice(rs.melebihi_asb) }}</h3><br>
                                        </div>
                                    </div>
                                </b-alert>
                            </div>
                        </div> -->
                        <router-link to="/asb_kewajaran_belanja/detail_dinas/asb">
                            <button type="button" @click="detailDinas(rs)"
                                class="btn btn-primary btn-block waves-effect waves-float waves-light"><i
                                    class="fa fa-list mr-1"></i>Detail Kegiatan</button>
                        </router-link>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <i class="fa fa-list"></i> Total Row {{ rs_dinas.lenght}}
            </div>
            <div class="col-6 ">
                <template>
                    <b-pagination v-model="currentPage" :total-rows=" rs_dinas.lenght" per-page='3' :align="'right'" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive'
    import {
        BAlert,
        BFormInput,
        BRow,
        BCol,
        BFormGroup,
        BPagination
    } from 'bootstrap-vue'

    export default {
        data() {
            return {
                currentPage: 1,
                rows: 0,
                cari: ""
            }
        },
        mixins: [Base],

        directives: {
            Ripple,
        },
        components: {
            BAlert,
            BFormInput,
            BRow,
            BCol,
            BFormGroup,
            BPagination
        },
        watch: {
            cari(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
            currentPage(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.input.rs_dinas,
                dinas: state => state.input.dinas,
            }),
        },
        mounted() {
            this.load_data('', '');
        },
        methods: {
            ...mapMutations({
                set_rs_dinas: 'set_rs_dinas',
                set_dinas: 'set_dinas'
            }),

            formatPrice(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            detailDinas(val) {
                this.set_dinas(val);
            },
            async load_data(cari, offset) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/dinas/get',
                        data: {
                            cari: cari,
                            offset: offset
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_dinas(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }

    }
</script>
</script>

<style>

</style>